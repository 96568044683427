/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx, Themed} from 'theme-ui'
import React from "react"
import {StaticImage} from 'gatsby-plugin-image'

export const Section1 = () => (
  <section style={{
    display: 'grid',
  }}>
    <div sx={{
      gridArea: '1/1',
      backgroundColor: 'black',
    }}>
      <StaticImage
        sx={{opacity: 0.6, height: '90vh'}}
        loading="eager"
        layout="fullWidth"
        src="../../images/landing-page-background.jpg"
        alt=""/>
    </div>
    <div
      style={{
        gridArea: "1/1",
        position: "relative",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem',
      }}
    >
      <div sx={{
        color: 'white',
        textAlign: 'center',
        lineHeight: 0.9,
        marginBottom: '1rem',
        fontSize: '100px',
        fontWeight: 'regular',
        maxWidth: '50rem',
        '@media only screen and (max-width: 600px)': {
          fontSize: '44px',
        },
      }}>
        We take our clients into the future.
      </div>

      <div sx={{
        color: 'white',
        textAlign: 'center',
        maxWidth: '50rem',
        fontSize: '22px',
        fontWeight:'medium',
      }}>
        ATX Defense partners with national security organizations to help integrate revolutionary technology, scalable
        processes, and long-term viability into their operations.
      </div>
    </div>
  </section>
)
