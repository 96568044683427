/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx, Themed} from 'theme-ui'
import * as React from 'react'
import {Section1} from '../sections/landing-page/section-1'
import {Section2} from '../sections/landing-page/section-2'
import {Section3} from '../sections/landing-page/section-3'
import {Section4} from '../sections/landing-page/section-4'
import {Section5} from '../sections/landing-page/section-5'
import {Layout} from '../layout/layout'

const IndexPage = () => (
  <Layout>
    <Section1/>
    <Section2/>
    <Section3/>
    <Section4/>
    {/* <Section5/> */}
  </Layout>
)

export default IndexPage
