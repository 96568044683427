import React from 'react'
import {Box} from 'theme-ui'
import {ThemeUIStyleObject} from '@theme-ui/css'

type Props = {
  icon: React.ReactElement
}

export const IconSquare = ({icon}: Props) => (
  <Box sx={{
    width: '80px',
    height: '80px',
    backgroundColor: 'atxOrange',
    borderRadius: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}>
    {icon}
  </Box>
)
