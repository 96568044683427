/** @jsx jsx */
// noinspection ES6UnusedImports
import {Box, jsx, Themed} from 'theme-ui'
import React from 'react'
import SDVOSBIcon from '../../graphics/sdvosb.svg'
import {SectionWhite} from '../../layout/section'

export const Section2 = () => (
  <SectionWhite>
    <Box sx={{
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
      <Themed.h3 sx={{
        maxWidth: '46rem',
        marginBottom: '1rem',
        fontWeight: 'medium',
      }}>
        An Austin-based consulting firm focused on
        serving the defense and national security
        community across the country.
      </Themed.h3>
      <div sx={{
        maxWidth: '50rem',
        marginBottom: '1rem',
        fontWeight: 'medium',
      }}>
        Founded by Army combat veterans, our team of experts carry over 50 years of national security experience
        across the
        Army, Navy, Air Force, NSA and CIA. We combine this with over 25 years of management consulting
        experience to give
        us a unique “dual-fluency” in the government and private sector—allowing us to transform
        mission-critical government
        operations with industry best practices.
      </div>
      <SDVOSBIcon sx={{height: '120px', width: 'auto'}}/>
    </Box>
  </SectionWhite>
)
