/** @jsx jsx */
// noinspection ES6UnusedImports
import {Box, jsx, Themed} from 'theme-ui'
import React from 'react'
import VMwareIcon from '../../graphics/vmware.svg'
import GoogleIcon from '../../graphics/google.svg'
import VirtruIcon from '../../graphics/virtruLogo.svg'
import {SectionGray} from '../../layout/section'

export const Section4 = () => (
  <SectionGray>
    <Box sx={{
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'atxDarkGray',
      fill: 'atxDarkGray',
    }}>
      <Themed.h2 sx={{
        marginBottom: '1rem',
        color: 'atxOrange',
        fontWeight: 'bold',
      }}>
        DISARMING BUREAUCRACY
      </Themed.h2>
      <Themed.h3 sx={{
        marginBottom: '2rem',
        color: 'inherit',
        fontWeight: 'regular',
      }}>
        with industry-leading technology.
      </Themed.h3>
      <div sx={{
        maxWidth: '50rem',
        marginBottom: '1.5rem',
        fontWeight: 'medium',
      }}>
          We are the first company to integrate Google Workspace into a DoD client’s workflow—providing unprecedented access to Controlled Unclassified Information (CUI) on personal devices to empower employees and boost productivity. We believe secure and compliant cloud-native collaboration tools are essential for productivity. 
      </div>
      <div sx={{
        fontSize: '22px',
        fontWeight: 'bold',
        marginBottom: '1rem',
      }}>
        Partnerships
      </div>
      <div sx={{
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
      }}>
        <GoogleIcon sx={{width: '9rem'}}/>
        <Box style={{width: '4rem'}}/>
        <VMwareIcon sx={{width: '9rem'}}/>
        <Box style={{width: '4rem'}}/>
        <VirtruIcon sx={{width: '9rem'}}/>
        
      </div>
    </Box>
  </SectionGray>
)
