/** @jsx jsx */
// noinspection ES6UnusedImports
import {Box, jsx, Themed} from 'theme-ui'
import React from 'react'
import DesignIcon from '../../graphics/design.svg'
import EngineerIcon from '../../graphics/engineer.svg'
import IntegrateIcon from '../../graphics/integrate.svg'
import DeployIcon from '../../graphics/deploy.svg'
import {IconSquare} from '../../layout/icon-square'
import {SectionBlack} from '../../layout/section'

export const Section3 = () => (
  <SectionBlack>
    <Box sx={{
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <Themed.h2 sx={{
        maxWidth: '46rem',
        marginBottom: '1rem',
        color: 'atxOrange',
        fontWeight: 'bold',
      }}>
        WHAT WE DO
      </Themed.h2>
      <Themed.h3 sx={{
        maxWidth: '50rem',
        marginBottom: '2rem',
        fontWeight: 'regular',
        color: 'inherit',
      }}>
        We lead full-service organizational transformations for high-visibility national security missions.
      </Themed.h3>
      <div sx={{
        fontSize: '26px',
        fontWeight: 'bold',
        marginBottom: '1rem',
      }}>
        What does this include?
      </div>


      <div sx={{
        display: 'flex',
        flexDirection: ['column', 'row'],
        gap: '1rem',
        padding: ['0 4rem', 0],
      }}>
        <ServiceType
          name="DESIGN"
          description="Designing an optimal organizational structure"
          icon={<DesignIcon/>}/>
        <ServiceType
          name="ENGINEER"
          description="Engineering and automating processes that best support that structure"
          icon={<EngineerIcon/>}/>
        <ServiceType
          name="INTEGRATE"
          description="Integrating technology and applications that best support those processes"
          icon={<IntegrateIcon/>}/>
        <ServiceType
          name="DEPLOY"
          description="Deploying the analytics needed to best measure the effectiveness of that technology"
          icon={<DeployIcon/>}/>
      </div>
    </Box>
  </SectionBlack>
)

type ServiceTypeProps = {
  name: string
  description: string
  icon: React.ReactElement
}

const ServiceType = ({name, description, icon}: ServiceTypeProps) => (
  <div sx={{
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0.5rem',
    gap: '0.5rem',
  }}>
    <IconSquare icon={icon}/>
    <div sx={{
      fontSize: '22px',
      fontWeight: 'bold',
    }}>
      {name}
    </div>
    <div sx={{
      fontSize: '16px',
      fontWeight: 'bold',
    }}>
      {description}
    </div>
  </div>
)
